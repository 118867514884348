import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';

import { BaseResponse } from '../models/rest/baseResponse';
import { IAppConfigurationBase } from './app-configuration.service';

export interface ICustomerConfig {
  VideoRecordingSaving: boolean;
  VoicePlatformEnabled: boolean;
  VoiceBotEnabled: boolean;
  EVChargersEnabled: boolean;
  PowerAutomateEnabled: boolean;
  MitteEnabled: boolean;
  ParkchirpEnabled: boolean;
}

interface IGetCustomerConfigResponse extends BaseResponse {
  Data: ICustomerConfig;
}

@Injectable({ providedIn: 'root' })
export class CustomerConfigService {
  private _config: ICustomerConfig;

  public get config(): ICustomerConfig {
    return this._config;
  }
  constructor(private _httpClient: HttpClient) {}

  loadCustomerConfig(appConfig: IAppConfigurationBase): Promise<IGetCustomerConfigResponse> {
    const path = `${appConfig.paths.baseUrl}/api/CustomerConfig`;
    return this._httpClient
      .get<IGetCustomerConfigResponse>(path)
      .pipe(
        tap(
          (response) => {
            this._config = Object.assign(this._config || {}, response.Data);
          },
          (error) => {
            console.log('Failed to load Customer configuration', error);
          },
        ),
      )
      .toPromise();
  }
}
